import * as React from 'react';
import {useState, useEffect} from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import { visuallyHidden } from '@mui/utils';

import CircularProgress from '@mui/material/CircularProgress';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


function getScore(array, element) {

  let scoreOrdered = []

  array.map((el) => {
    scoreOrdered.push(el.clasificacion)
  })
  const score = scoreOrdered.sort(function(a, b){return b-a});
  const result = score.indexOf(element) + 1
  return result;
}

const headCells = [
  {
    id: 'nombre',
    numeric: false,
    disablePadding: true,
    label: 'Jugadores',
    alt: "Nombre"
  },
  {
    id: 'puntuacion',
    numeric: true,
    disablePadding: true,
    sortable: false,
    label: 'Ranking',
    alt: "Ranking"
  },
  {
    id: 'clasificacion',
    numeric: true,
    disablePadding: false,
    label: 'Clasificación (new)',
    alt: '(Puntos por partido / (partidos_jugados - partidos_jugados * (partidos_jugados - 1) / 200)) + coeficiente de golaverage'
  },
  {
    id: 'clasificacionOld',
    numeric: true,
    disablePadding: false,
    label: 'Clasificación (old)',
    alt: '(Puntos por partido / por partidos Jugados) + coeficiente de golaverage'
  },
  {
    id: 'partidosJ',
    numeric: true,
    disablePadding: false,
    label: 'P J',
    alt: 'Partidos Jugados'
  },
  {
    id: 'partidosG',
    numeric: true,
    disablePadding: false,
    label: 'P G',
    alt: 'Partidos Ganados'
  },
  {
    id: 'partidosE',
    numeric: true,
    disablePadding: false,
    label: 'P E',
    alt: 'Partidos Empatados'
  },
  {
    id: 'partidosP',
    numeric: true,
    disablePadding: false,
    label: 'P P',
    alt: 'Partidos Perdidos'
  },
  {
    id: 'golaverage',
    numeric: true,
    disablePadding: false,
    label: 'Gol average',
    alt: 'Goles a favor (-) goles en contra'
  },
  {
    id: 'puntos',
    numeric: true,
    disablePadding: false,
    label: 'Puntos',
    alt: 'Puntos por partido'
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>

        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >

              <Tooltip title={headCell.alt} arrow>
                <b> {headCell.label}</b>
              </Tooltip>

              {
                orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null
              }
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}


function getPlayers(setIsLoading) {
  const [players, setPlayers] = useState([])

    useEffect(() => {
      
      axios.get(process.env.REACT_APP_BASE_URL+'/app/api/jugadores/')
      .then(res => {
          setPlayers(res.data);
          /* {
            setTimeout(function () {
              setIsLoading(false);
            }, 50000)
          } */
          setIsLoading(false);
      })
      .catch(error => {
          console.log(error)
      })

  }, [])
  
  return  ( players )
}


export default function EnhancedTable() {
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('clasificacion');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [isLoading, setIsLoading] = useState(true);
  const rows = getPlayers(setIsLoading);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 50));
    setPage(0);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;


  function resetAllPlayers ()  {

    setIsLoading(true)

    axios.get(process.env.REACT_APP_BASE_URL+'/app/api/jugadores/')
    .then(res => {
      const lista = res.data
      lista.map((jugador) => {
        axios.put(process.env.REACT_APP_BASE_URL+'/app/api/jugadores/'+ jugador.id, 
            {
              "partidosG": 0,
              "partidosJ": 0,
              "partidosE": 0,
              "partidosP": 0,
              "puntos": 0,
              "golaverage": 0,
              "clasificacion": 0.0000,
              "clasificacionOld": 0.0000
          }
        )
        .then(
          setIsLoading(false)
        )
        .catch(error => {
            console.log(error)
        })
      })
    })
    .catch(error => {
        console.log(error)
    })
  }


  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    
    <Box sx={{ width: '100%' }}>
      
      <Paper sx={{ width: '100%', mb: 2, overflow: 'hidden' }}>

          {
            isLoading ?  
              <Box  className="root-loading">
                <CircularProgress />
              </Box> : 
              <Box ></Box>
          }

        <TableContainer sx={{ maxHeight: 490 }}>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            stickyHeader  aria-label="sticky table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
  
          {


              stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.nombre);
                  const labelId = `enhanced-table-checkbox-${index}`;        

                  let nombreCompleto = row.nombre + ' ' +  row.apellido;

                  const newCriteria = row.partidosJ ? (row.partidosJ - row.partidosJ * (row.partidosJ - 1) / 200) : 1
                  const OldCriteria = row.partidosJ ? row.partidosJ + (row.golaverage * 0.0001) : 1
        
                  let counterNew = (row.puntos/newCriteria).toFixed(2)
                  /* let counterOld = (row.puntos/(row.partidosJ ? row.partidosJ : 1)) + (row.golaverage * 0.0001).toFixed(2) */
                  let counterOld = (row.puntos/OldCriteria).toFixed(2)

                  const str = "" + Math.abs(row.golaverage)
                  const pad = "000"
                  const coeficienteGolaverage = (row.golaverage < 0 ? '-' : '+') + pad.substring(0, pad.length - str.length) + str
            
                  const numI = getScore(rows, row.clasificacion)

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >

                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                      >
                        {nombreCompleto} 
                      </TableCell>
                      
                      <TableCell align="right">
                        <span className="numI">
                          {numI}
                        </span>
                      </TableCell>

                      <TableCell align="right">
                        <span className="score-column--counter">
                          {
                            counterNew
                          }
                        </span>
                        
                        <span className="separator"> | </span>
                        <span className="score-column--goals active">
                          {
                            coeficienteGolaverage
                          }
                        </span>
                      </TableCell>
                      
                      <TableCell align="right">                       
                        <span className="score-column--counter">
                          {
                            counterOld <= 0 ? '0.00' : counterOld
                          }
                        </span>
                        <span className="separator"> | </span>
                        <span className="score-column--goals active">
                          {
                            coeficienteGolaverage
                          }
                        </span>
                      </TableCell>
                      <TableCell align="right">{row.partidosJ}</TableCell>
                      <TableCell align="right">{row.partidosG}</TableCell>
                      <TableCell align="right">{row.partidosE}</TableCell>
                      <TableCell align="right">{row.partidosP}</TableCell>
                      <TableCell align="right">{row.golaverage}</TableCell>
                      <TableCell align="right">{row.puntos}</TableCell>
                    </TableRow>
                  );
                })
                }
                {
                  emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )
                }
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          labelRowsPerPage={"Filas por página"} 
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

      </Paper>

   {/*    <Button onClick={resetAllPlayers}>borrar puntuaciones de todos los jugadores</Button> */}

    </Box>
  );
}


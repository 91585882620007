import React from "react";
import Box from "@mui/material/Box";
import SigninComponent from '../components/signinComponent';

export default function Home() {
	return (
		<Box
			align="center"
			sx={{ display: 'flex', flexDirection: 'column' }}
		>
            <Box className='content-pagina'
                sx={{
                }}>
                <SigninComponent      />
            </Box>
       </Box>
	);
}

import { format } from 'date-fns'
import * as React from 'react';
import {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import ListItemIcon from '@mui/material/ListItemIcon';
import AccountCircle from '@mui/icons-material/AccountCircle';

import TransitionsModal from './modal';
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const baseURL = process.env.REACT_APP_BASE_URL+"/app/api/partidos/";
    const baseURLJugadores = process.env.REACT_APP_BASE_URL+"/app/api/jugadores/";
    const defaultValues = {
        id: "",
        fecha: "",
        resultado: "",
        goles_B: "",
        goles_N: "",
        puntos: "",
    };

    const MenuProps = {
        PaperProps: {
            style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
            },
        },
    };

    const getPlayers = () => {
        
        const [players, setPlayers] = React.useState([])

        useEffect(() => {
            axios.get(baseURLJugadores)
            .then(res => {
/*                 console.log(res) */
                setPlayers(res.data)
            })
            .catch(error => {
                console.log(error)
            })

        }, [])

        return  ( players )
    }

export default function formGame() {

    const [equipo_B, setEquipo_B] = React.useState([]);
    const [equipo_N, setEquipo_N] = React.useState([]);
    const [formValues, setFormValues] = React.useState(defaultValues);
    const [dateTime, setDateTime] = React.useState(Date)
    const [goles_B, setGolesB] = React.useState(0);
    const [goles_N, setGolesN] = React.useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState(false);
    const [titleMessage] = useState('El partido se ha registrado correctamente');
    
    
    const jugadores = getPlayers()

    const handleChangeEquipoB = (event) => {
      const {
        target: { value }
      } = event;
      setEquipo_B(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    };

    const handleChangeEquipoN = (event) => {
        const {
          target: { value },
        } = event;
        setEquipo_N(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChangeGolesB = (event) => {
        setGolesB(event.target.value);
    };
    
    const handleChangeGolesN = (event) => {
        setGolesN(event.target.value);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
        ...formValues,
        [name]: value,
        });
    };


    const getReload = () => {
        setTimeout(() =>{
            console.log('modal open')
            setIsLoading(false)
            setMessage(true)
        }, 1500)
    }


    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true)

        async function callScore () {
            const date = format(new Date(dateTime), 'yyyy/MM/dd');
            const time = format(new Date(dateTime), 'hh:mm');
            const id = format(new Date(dateTime), 'yyyy-MM-dd');
    
            return new Promise((resolve, reject) => {
                
            // se registra la info y se crea el partido
                    try {
                        resolve(
                            axios.post(baseURL, 
                            {
                                "id": id,
                                "fecha": date,
                                "hora": time,
                                "equipo_B": equipo_B,
                                "equipo_N": equipo_N,
                                "goles_B": parseInt(goles_B),
                                "goles_N": parseInt(goles_N),
                                "resultado": (goles_B + ' - '+ goles_N)
                            },
                            {
                                'Content-Type': 'text/plain'
                            }
                        ))
                    }
                    catch(err) {
                        reject(console.log(err),
                        alert('error registrando el partido por: ' + err))
                    }
            
            // se actualizan los datos de los jugadores en función de cómo ha ido el partido
                    try {
                    //si hay empate
                        if (goles_B === goles_N) {
                            console.log('empate')
            
                            const jugadoresPartido = equipo_B.concat(equipo_N);
                            console.log('jugadoresDelPartido: ', jugadoresPartido)        
                                
                            resolve(jugadores.map((jugador) => {
                                
                                const nombreCompleto = jugador.nombre + ' ' + jugador.apellido
            
                                jugadoresPartido.forEach( async (element)  =>  {
                        
                                        if (element === nombreCompleto) {
            
                                            try {
                                                await axios.put(process.env.REACT_APP_BASE_URL+'/app/api/jugadores/puntos/'+ jugador.id,
                                                {
                                                    "puntos": jugador.puntos + 1,
                                                    "partidosJ": jugador.partidosJ + 1,
                                                    "partidosE": jugador.partidosE + 1,
                                                    "golaverage": jugador.golaverage + (goles_B - goles_N),
                                                    "partidosG": jugador.partidosG,
                                                    "partidosP": jugador.partidosP,
                                                    "clasificacion": jugador.clasificacion,
                                                    "clasificacionOld": jugador.clasificacionOld
                                                })
            
                                            }
                                            catch(e) {
                                                console.log(e)
                                                alert('se ha producido un error')                                        
                                            }
                                    }
                                })
            
                            }))
            
                        }
                        
                    //si ganan los blancos
                        if (goles_B > goles_N) {
                            console.log('ganan los blancos:  ' + equipo_B)
            
                            resolve(jugadores.map((jugador) => {
            
                                const nombreCompleto = jugador.nombre + ' ' + jugador.apellido
            
                                equipo_N.forEach(element => {
                                    if (element === nombreCompleto) {
                                        
                                            axios.put(process.env.REACT_APP_BASE_URL+'/app/api/jugadores/puntos/'+jugador.id,
                                            {
                                                "partidosJ": jugador.partidosJ + 1,
                                                "partidosE": jugador.partidosE,
                                                "partidosG": jugador.partidosG,
                                                "partidosP": jugador.partidosP + 1,                                    
                                                "golaverage": jugador.golaverage + (goles_N - goles_B),
                                                "puntos": jugador.puntos
                                            }
                                        )
                                    }
                                })
            
                                equipo_B.forEach(element => {
                                    const nombreCompleto = jugador.nombre + ' ' + jugador.apellido
                                
                                    if (element === nombreCompleto) {
                                            axios.put(process.env.REACT_APP_BASE_URL+'/app/api/jugadores/puntos/'+jugador.id,
                                            {
                                                "puntos": jugador.puntos + 3,
                                                "partidosJ": jugador.partidosJ + 1,
                                                "partidosE": jugador.partidosE,
                                                "partidosG": jugador.partidosG + 1,
                                                "partidosP": jugador.partidosP,
                                                "golaverage": jugador.golaverage +  (goles_B - goles_N)
                                            }
                                        )
                            
                                    }
                                })
                            }))
                            
                        }
                        
                    //si ganan los negros
                        if (goles_N > goles_B) {
                            
                            console.log('ganan los negros:  ' + ' // ' + equipo_N)
            
                            resolve(jugadores.map((jugador) => {
                                const nombreCompleto = jugador.nombre + ' ' + jugador.apellido
                                
                                equipo_B.forEach(element => {
                                    if (element === nombreCompleto) {
                                        axios.put(process.env.REACT_APP_BASE_URL+'/app/api/jugadores/puntos/'+jugador.id,
                                            {
                                                "partidosJ": jugador.partidosJ + 1,
                                                "partidosE": jugador.partidosE,
                                                "partidosG": jugador.partidosG,
                                                "partidosP": jugador.partidosP + 1,
                                                "golaverage": jugador.golaverage + (goles_B - goles_N),
                                                "puntos": jugador.puntos,
                                                "clasificacion": jugador.clasificacion,
                                                "clasificacionOld": jugador.clasificacionOld
                                            }
                                        )
                                        .then(res => {
                                            console.log(res)
                                        })
                                        .catch(err => {
                                            console.log(err)
                                        })
                                        
                                    }
                                })
            
                                equipo_N.forEach(element => {
                                    const nombreCompleto = jugador.nombre + ' ' + jugador.apellido
                                
                                    if (nombreCompleto === element) {
                                        axios.put(process.env.REACT_APP_BASE_URL+'/app/api/jugadores/puntos/'+jugador.id,
                                            {
                                                "puntos": jugador.puntos + 3,
                                                "partidosJ": jugador.partidosJ + 1,
                                                "partidosE": jugador.partidosE,
                                                "partidosG": jugador.partidosG + 1,
                                                "golaverage": jugador.golaverage + (goles_N - goles_B),
                                                "partidosP": jugador.partidosP,
                                                "clasificacion": jugador.clasificacion,
                                                "clasificacionOld": jugador.clasificacionOld
                                            }
                                        )
                                        .then(res => {
                                            console.log(res)
                                        })
                                        .catch(error => {
                                            console.log(error)
                                        })
                                    }
                                })
                            }))
            
                        }
                    }
                    catch (error) {
                        reject(
                            console.log(error),
                            alert('error registrando el partido por: ' + error.message)
                        )
                    }


            })

        }
        callScore().then((res) => {
            console.log('after second try catch')
            console.log(res)
            setEquipo_B([])
            setEquipo_N([])
            setGolesN(0)
            setGolesB(0)
            setDateTime(Date)
            setFormValues([])
            getReload()
        })    

    };


    return (

        <div className="background-form">
            <Paper className="background-form" elevation={3} />
                {
                    isLoading ?  
                    <Box  className="root-loading">
                        <CircularProgress />
                    </Box> : 
                    <Box ></Box>
                }
                
                <TransitionsModal message={message} key={message} titleMessage={titleMessage} />

                <h1> Registrar nuevo partido</h1>
                <form onSubmit={handleSubmit}>
                    <Box sx={{   xs:{width: 400}, sm:{ width: 400}   }}
                        >

                        <Box 
                                sx={{
                                    display: 'flex',
                                    alignItems:'left',
                                    '& > :not(style)': { m: 1 },
                                    
                                    flexDirection: { xs: 'column', sm: 'row'},
                                }}
                                className="content-select-add-game">
                                
                                <Box sx={{ width: 200}} className="title-select-players-white">
                                    Equipo <br /> blanco
                                </Box>


                                <Box sx={{
                                    alignItems: 'left', 
                                }}
                                >

                                    <p>
                                        <span align="left">Selecciona jugadores del equipo Blanco: </span>
                                    </p>
                                    <FormControl 
                                        fullWidth 
                                        sx={{ m: 1, width: 280 }}
                                    >
                                        <InputLabel id="multiple-checkbox-label-equipoB">Jugadores</InputLabel>
                                        <Select
                                            required
                                            labelId="multiple-checkbox-label-equipoB"
                                            id="multiple-checkbox-equipoB"
                                            multiple
                                            value={equipo_B}
                                            onChange={handleChangeEquipoB}
                                            input={<OutlinedInput label="Jugadores" />}
                                            renderValue={(selected) => selected.join(', ')}
                                            MenuProps={MenuProps}
                                        >
                                            {
                                                jugadores.map((jugadorB) => (
                                                    <MenuItem key={jugadorB.id} 
                                                        value={jugadorB.nombre + ' ' + jugadorB.apellido}
                                                    >
                                                        <Checkbox checked={equipo_B.indexOf(jugadorB.nombre + ' ' + jugadorB.apellido) > -1} />
                                                        <ListItemText primary={jugadorB.nombre + ' ' + jugadorB.apellido} />
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box sx={{width: 300}}>
                                    <p>
                                        <span align="left">Seleccionados: { equipo_B.length} </span>
                                    </p>
                                    <div>
                                        {
                                            equipo_B.length ? equipo_B.map((jugadoresB) =>
                                                (
                                                    <span key={jugadoresB} className="player-team-span">
                                                        <Chip icon={<AccountCircle />}  size="small" className="player-team-chip" label={jugadoresB} />                                                    
                                                    </span>
                                                )
                                                
                                            ) : ''
                                        }
                                    </div>
                                </Box>
                        </ Box>
                        <hr className="line-hor-form"></hr>
                        
                        <Box      
                            sx={{
                                display: 'flex',
                                alignItems: 'left',
                                '& > :not(style)': { m: 1 },
                                flexDirection: { xs: 'column', sm: 'row'},
                            }}
                            className="content-select-add-game"
                        >     
                             <Box sx={{ width: 200}}  className="title-select-players-black">
                                 Equipo <br></br> negro
                            </Box>  
                            <Box sx={{
                                alignItems: 'left'}}
                                >

                                <p><span align="left">Selecciona jugadores del equipo Negro:</span></p>
                                <FormControl 
                                    fullWidth 
                                    sx={{ m: 1, width: 280 }}
                                >
                                    <InputLabel id="multiple-checkbox-label-equipoN">Jugadores</InputLabel>
                                    <Select
                                        labelId="multiple-checkbox-label-equipoN"
                                        id="multiple-checkbox-equipoN"
                                        multiple
                                        required
                                        value={equipo_N}
                                        onChange={handleChangeEquipoN}
                                        input={<OutlinedInput label="Jugadores" />}
                                        renderValue={(selected) => selected.join(', ')}
                                        MenuProps={MenuProps}
                                    >
                                    {
                                        jugadores.map((jugadorN) => (
                                            <MenuItem key={jugadorN.id} 
                                                value={jugadorN.nombre + ' ' + jugadorN.apellido}
                                            >
                                                <Checkbox checked={equipo_N.indexOf(jugadorN.nombre + ' ' + jugadorN.apellido) > -1} />
                                                <ListItemText primary={jugadorN.nombre + ' ' + jugadorN.apellido} />
                                            </MenuItem>
                                        ))
                                    }
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box sx={{width: 350}}>
                                
                                <p><span align="left">Seleccionados: { equipo_N.length}</span></p>
                                <div>
                                    {
                                        equipo_N.length ? equipo_N.map((jugadoresN) =>
                                            (
                                                <span key={jugadoresN} className="player-team-span">
                                                    
                                                    <ListItemIcon className="listIconStyle" >
                                                        <Chip icon={<AccountCircle />}  size="small" className="player-team-chip" label={jugadoresN} />   
                                                    </ListItemIcon>   
                                                                                               
                                                </span>
                                            )
                                            
                                        ) : ''
                                    }
                                </div>
                            </ Box>
                        </ Box>


                        
                        <hr className="line-hor-form"></hr>
                            <br/>
                        <Box      
                            sx={{
                            display: 'flex',
                            alignItems: 'center',
                            '& > :not(style)': { m: 1 },
                            }}>
                            
                            <FormControl fullWidth>
                                    <InputLabel id="goles_Blancos">Goles e. Blanco</InputLabel>
                                        <Select
                                            labelId="goles_Blancos"
                                            id="goles_B"
                                            value={goles_B}
                                            label="Goles e. Blanco"
                                            onChange={handleChangeGolesB}
                                        >
                                            { 
                                                [...Array(31)].map((element, index) => ( 
                                                    <MenuItem value={index} key={index}>{index}</MenuItem>
                                                ))
                                            }
                                        </Select>
                            </FormControl>

                            <FormControl fullWidth>
                                <InputLabel id="goles_Negros">Goles e. Negro</InputLabel>
                                    <Select
                                        labelId="goles_Negros"
                                        id="goles_N"
                                        value={goles_N}
                                        label="Goles e. Negro"
                                        onChange={handleChangeGolesN}
                                    >
                                        { 
                                            [...Array(31)].map((elementInArray, index) => ( 
                                                <MenuItem value={index} key={index}>{index}</MenuItem>
                                            ))
                                        }
                                    </Select>
                            </FormControl> 
                        
                            <FormControl fullWidth>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DateTimePicker
                                        renderInput={(props) => <TextField {...props} />}
                                        label="Fecha del partido"
                                        value={dateTime}
                                        onChange={
                                            (newDateTime) => {
                                                setDateTime(newDateTime);
                                            }
                                        }
                                    />
                                </LocalizationProvider>
                            </FormControl>
                                
                            </ Box>
                            <Box      
                                sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '& > :not(style)': { m: 1 },
                                }}>
                            </ Box>
                            <Box      
                                sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '& > :not(style)': { m: 1 },
                                }}>
                            </Box>

                        <div><br /><br /></div>
                        <Box>
                            <Button
                                type="submit"
                                className='button-primary'
                                variant="contained"
                                color="primary"
                            >
                                Registrar partido
                            </Button>
                        </Box>
                    </Box>
                </form>
                
        </div>
        )
    
}

import React from 'react';
import TablaPartidos from '../components/tablaPartidos'; 
import { Link } from 'react-router-dom';
import Box from "@mui/material/Box";

export default function Partidos() {

    return (
        <div>
            <Box
                align="center"
                sx={{ display: 'flex', flexDirection: 'column' }}
            >   
                <br />

                <h1> Registro de partidos jugados</h1>
                <br />
                <TablaPartidos />
                <div className="menu-add-results">
                    <Box 
                        sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'right' }}>
                        <Link to="/partidos-admin">
                            Admin
                        </Link>

                    </Box>
                </div>
            </Box>
        </div>
    )
}

import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';


import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';

import axios from 'axios';

import '../styles.css';

const baseURL = process.env.REACT_APP_BASE_URL+"/app/api/jugadores/";

const defaultValues = {
    id: "",
    nombre: "",
    apellido: "",
    email: "",
    telefono: "",
    partidosJ: 0,
    partidosG: 0,
    partidosE: 0,
    partidosP: 0,
    puntos: 0,
    golaverage: 0,
    clasificacion: 0,
  };

function formPlayer() {

    const [formValues, setFormValues] = React.useState(defaultValues);

    const [value, setValue] = React.useState('')

/*     const handleChanged = (newValue) => {
      setValue(newValue)
    } */

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
        ...formValues,
        [name]: value,
        });
    };
/* 
    const handleHiddenChange = (name) => (e, value) => {
        setFormValues({
            id: this.id
        });
      }; */

      
    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(formValues);
        axios.post(baseURL, 
            {
                "id": formValues.id,
                "nombre": formValues.nombre,
                "apellido": formValues.apellido,
                "email": formValues.email,
                "telefono": formValues.telefono,
                "partidosJ": 0,
                "partidosG": 0,
                "partidosP": 0,
                "partidosE": 0,
                "puntos": 0,
                "golaverage": 0,
                "clasificacion": 0,
                "clasificacionOld": 0,
            },
            {
                'Content-Type': 'text/plain'
            }
        )
        .then(res => {
            console.log(res)
        })
        .catch(error => {
            console.log(error)
        })

    };
  
    return (
        <div>
            <h1> Registrar nuevo jugador</h1>
           <form onSubmit={handleSubmit}>
                <Box sx={ {
                    }}>
                    <Box 
                       sx={{
                         '& .MuiTextField-root': { m: 1, width: '25ch' },
                       }}>
                        <FormControl
                            >
                            <TextField
                                required
                                id="nombre"
                                name="nombre"
                                label="nombre"
                                value={formValues.nombre}
                                onChange={handleInputChange}
                            />
                        </FormControl>

                        <FormControl
                                >
                            <TextField
                                required
                                id="apellido"
                                name="apellido"
                                label="apellido"
                                value={formValues.apellido}
                                onChange={handleInputChange}
                            />
                        </FormControl>
               
                        <FormControl
                                >
                            <TextField
                                className="hidden-input hidden"
                                type="hidden"
                                id="id"
                                name="id"
                                label="id"
                                value={formValues.id = formValues.nombre + '_' + formValues.apellido}
                                onChange={handleInputChange}
                            />
                        </FormControl>

                        <FormControl 
                                >
                            <TextField
                                    required
                                    id="telefono"
                                    name="telefono"
                                    label="telefono"
                                    value={formValues.telefono}
                                    onChange={handleInputChange}
                                    inputProps={{ inputMode: 'numeric', maxLength: 9 , pattern: '[0-9]*' }}
                                />
                        </FormControl>

                        <FormControl>
                            <TextField
                                    required
                                    className='form-input'
                                    id="email"
                                    name="email"
                                    label="email"
                                    value={formValues.email}
                                    onChange={handleInputChange}
                                />
                        </FormControl>

                    </Box>
                <div><br /><br /></div>
                    <Box>
                        <Button
                            type="submit"
                            className='button-primary'
                            variant="contained"
                            color="primary"
                        >
                            Registrar jugador
                        </Button>
                    </Box>
                </Box>
            </form>
        </div>
        )
    
}


export default formPlayer
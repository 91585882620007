import React from 'react';
import FormPlayer from '../components/formAddPlayer';
import ListaJugadores from '../components/listaJugadores';
import Box from '@mui/material/Box';


export default function Jugadores() {
    return (
        <Box>
            <Box>
                <h1>
                    Lista de jugadores 
                </h1>
            </Box>
            < ListaJugadores />
                <br></br><br></br>
       {/*      < FormPlayer /> */}
        
        </Box>
    )
}
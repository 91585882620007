import * as React from 'react';
import {useState, useEffect} from 'react';
import axios from 'axios';

import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';

import { alpha } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import { visuallyHidden } from '@mui/utils';

import TransitionsModal from './modal';


function getRows(setIsLoading) {
    const [rows, setRows] = useState([])
  
    useEffect(() => {
        axios.get(process.env.REACT_APP_BASE_URL+'/app/api/partidos/')
        .then(res => {
            setRows(res.data)
            setIsLoading(false);
        })
        .catch(error => {
            console.log(error)
        })

    }, [])
  return  ( rows )
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


function EnhancedTableHead(props) {

  const { onSelectAllClick, order, orderBy, numSelected, gameSelected, rowCount, onRequestSort } =
    props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'selecciona todos los partidos',
            }}
          />
        </TableCell>
        {columnas.map((columna) => (
          <TableCell
            key={columna.id}
            align={columna.numeric ? 'right' : 'left'}
            padding={columna.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === columna.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === columna.id}
              direction={orderBy === columna.id ? order : 'asc'}
              onClick={createSortHandler(columna.id)}
            >
              {columna.label}
              {orderBy === columna.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'orden descendente' : 'orden ascendente'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}


EnhancedTableHead.propTypes = {
  gameSelected: PropTypes.array.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


const EnhancedTableToolbar = (props) => {
  
  const [isLoading, setIsLoading] = useState(false);
  const { numSelected } = props;
  const { gameSelected } = props;

  const [message, setMessage] = useState(false);

  const [titleMessage, setTitleMessage] = useState('');

  const getReload = () => {
    setTimeout(() =>{
        console.log('modal open')
        setIsLoading(false)
        setMessage(true)
        setTitleMessage('El partido se ha eliminado correctamente')
    }, 1500)
  }

  const FormDeleteGameTest =  async (gameSelected) => {
/*     console.log('partido para borrar: ' + gameSelected) */

    async function updateData() {
      try {
        setIsLoading(true);
        await axios.delete(process.env.REACT_APP_BASE_URL+'/app/api/partidos/'+ gameSelected)
        .then(
            axios.get(process.env.REACT_APP_BASE_URL+'/app/api/partidos/'+ gameSelected)
            .then(res => {
                const dataGame = res.data
    // Hay empate
                if (dataGame.goles_B === dataGame.goles_N) {
                  console.log('fue empate')
                  const jugadores = dataGame.equipo_N.concat(dataGame.equipo_B)
    
      // se restaura las puntuaciones de todos los jugadores
                  jugadores.map((jugador, index) => {
                    const modNombre = jugador.replace(/\s/g, "_")
                    try {
                        axios.get(process.env.REACT_APP_BASE_URL+'/app/api/jugadores/'+modNombre)
                        .then(res => {
                          try {
                            axios.put(process.env.REACT_APP_BASE_URL+'/app/api/jugadores/puntos/'+ modNombre,
                              {
                                  "puntos": res.data.puntos - 1,
                                  "partidosJ": res.data.partidosJ - 1,
                                  "partidosE": res.data.partidosE - 1,
                                  "partidosG": res.data.partidosG,
                                  "partidosP": res.data.partidosP,
                                  "golaverage":res.data.golaverage,
                                  "clasificacion": 1,
                                  "clasificacionOld": 1
                              }
                            )
                          }
                          catch (err) {
                            console.log(err)
                            alert(' ha ocurrido un error')
                          }
                        })
                    }
                    catch (err) {
                      console.log(err)
                      alert(' ha ocurrido un error')
                    }
                  })
                  
                }
    
    // Ganan los blancos
                if (dataGame.goles_B > dataGame.goles_N) {
                  console.log('en el partido ganaron los blancos')
    
      // se restaura la puntuación de los blancos
                  dataGame.equipo_B.map((jugador, index) => {
                  const modNombre = jugador.replace(/\s/g, "_")
    
                      try {
                        axios.get(process.env.REACT_APP_BASE_URL+'/app/api/jugadores/'+modNombre)
                        .then(res => {
                            try {
                              axios.put(process.env.REACT_APP_BASE_URL+'/app/api/jugadores/puntos/'+ modNombre,
                                {
                                    "puntos": res.data.puntos - 3,
                                    "partidosJ": res.data.partidosJ - 1,
                                    "partidosE": res.data.partidosE,
                                    "partidosG": res.data.partidosG - 1,
                                    "partidosP": res.data.partidosP,
                                    "golaverage": res.data.golaverage - (dataGame.goles_B - dataGame.goles_N),
                                    "clasificacion": 1,
                                    "clasificacionOld": 1
                                }
                              )
                            }
                            catch (err) {
                              console.log(err)
                              alert(' ha ocurrido un error')
                            }
                        })
                      }
                      catch(err) {
                        console.log(err)
                        alert(' ha ocurrido un error')
                      }
    
                  })
    
      // se restaura la puntuación de los negros
                  dataGame.equipo_N.map((jugador, index) => {
                  const modNombre = jugador.replace(/\s/g, "_")
    
                      try {
                          axios.get(process.env.REACT_APP_BASE_URL+'/app/api/jugadores/'+modNombre)
                          .then(res => {
    
                            try {
                              axios.put(process.env.REACT_APP_BASE_URL+'/app/api/jugadores/puntos/'+ modNombre,
                                {
                                    "puntos": res.data.puntos,
                                    "partidosJ": res.data.partidosJ - 1,
                                    "partidosE": res.data.partidosE,
                                    "partidosG": res.data.partidosG,
                                    "partidosP": res.data.partidosP - 1,
                                    "golaverage": res.data.golaverage + (dataGame.goles_B - dataGame.goles_N),
                                    "clasificacion": 1,
                                    "clasificacionOld": 1
                                }
                              )
                            }
                            catch (err) {
                              console.log(err)
                              alert(' ha ocurrido un error')
                            }
                          
                          })
                      }
                      catch (err){
                        console.log(err)
                        alert(' ha ocurrido un error')
                      }
                  })
                }
    
    // Ganan los negros
                if (dataGame.goles_N > dataGame.goles_B) {
                  console.log('en el partido ganaron los negros')
    
      // se restaura la puntuación de los negros
                  dataGame.equipo_N.map((jugador, index) => {
                  const modNombre = jugador.replace(/\s/g, "_")
                      try {
                        axios.get(process.env.REACT_APP_BASE_URL+'/app/api/jugadores/'+modNombre)
                        .then(res => {
                          try {
                            axios.put(process.env.REACT_APP_BASE_URL+'/app/api/jugadores/puntos/'+ modNombre,
                              {
                                  "puntos": res.data.puntos - 3,
                                  "partidosJ": res.data.partidosJ - 1,
                                  "partidosE": res.data.partidosE,
                                  "partidosG": res.data.partidosG - 1,
                                  "partidosP": res.data.partidosP,
                                  "golaverage": res.data.golaverage - (dataGame.goles_N - dataGame.goles_B),
                                  "clasificacion": 1,
                                  "clasificacionOld": 1
                              }
                            )
                          }
                          catch(err){
                            console.log(err)
                            alert(' ha ocurrido un error')
                          }
                        })
                      }
                      catch (err) {
                        console.log(err)
                        alert(' ha ocurrido un error')
                      }
                  })
    
      // se restaura la puntuación de los blancos
                  dataGame.equipo_B.map((jugador, index) => {
                  const modNombre = jugador.replace(/\s/g, "_")
                    try {
                      axios.get(process.env.REACT_APP_BASE_URL+'/app/api/jugadores/'+modNombre)
                      .then(res => {
                          try {
                            axios.put(process.env.REACT_APP_BASE_URL+'/app/api/jugadores/puntos/'+ modNombre,
                              {
                                  "puntos": res.data.puntos,
                                  "partidosJ": res.data.partidosJ - 1,
                                  "partidosE": res.data.partidosE,
                                  "partidosG": res.data.partidosG,
                                  "partidosP": res.data.partidosP - 1,
                                  "golaverage": res.data.golaverage + (dataGame.goles_N - dataGame.goles_B),
                                  "clasificacion": 1,
                                  "clasificacionOld": 1
                              }
                            )
                          }
                          catch(e) {
                            console.log(e)
                            alert(' ha ocurrido un error')
                          }
                      })
                    }
                    catch(err) {
                      console.log(err)
                      alert(' ha ocurrido un error')
                    }
                  })
    
                }
            })
            .catch(error => {
                console.log(error)
                alert(' ha ocurrido un error')
            }),
        ).then(() => {
        })
      }
      catch(error) {
        console.log(error)
        alert(' ha ocurrido un error')
      }
    }
  
    updateData().then((res) => {
      /* console.log('now' + res) */
      getReload()
    })    

  }

  return (
    <form >
    {
      isLoading ?  
        <Box  className="root-loading">
          <CircularProgress />
        </Box> : 
        <Box ></Box>
    }
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
            ...(numSelected > 0 && {
              bgcolor: (theme) =>
                alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
            }),
          }}
        >
          
        <TransitionsModal message={message} key={message}  titleMessage={titleMessage} />

          {numSelected > 0 ? (
            <Typography
              sx={{ flex: '1 1 100%' }}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
            {numSelected} seleccionado
            </Typography>
          ) : (
            <Typography
              sx={{ flex: '1 1 100%' }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Partidos
            </Typography>
          )}

          {numSelected > 0 ? (
            <div>
              
            <Tooltip title="Delete">

              <IconButton type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  FormDeleteGameTest(gameSelected)
                }}
              >
                <DeleteIcon />
               
              </IconButton>
              
            </Tooltip>

           </div>
          ) : (
            <Tooltip title="Filter list">
              <IconButton>
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          )}
        </Toolbar>
    
    </form>
  );
};

const columnas = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'Partidos',
  },
  {
    id: 'equipo_B',
    numeric: false,
    disablePadding: false,
    label: 'Equipo Blanco',
  },
  {
    id: 'equipo_N',
    numeric: false,
    disablePadding: false,
    label: 'Equipo Negro',
  },
  {
    id: 'fecha',
    numeric: false,
    disablePadding: false,
    label: 'Fecha',
  },
  {
    id: 'hora',
    numeric: false,
    disablePadding: false,
    label: 'Hora',
  },
  {
    id: 'goles_B',
    numeric: true,
    disablePadding: false,
    label: 'Goles E. B.',
  },
  {
    id: 'goles_N',
    numeric: true,
    disablePadding: false,
    label: 'Goles E. N.',
  },
  {
    id: 'resultado',
    numeric: false,
    disablePadding: false,
    label: 'Resultados',
  }
];

const passengers = [
  {
    id: 1,
    passengerName: "Freddie Mercury",
    isVegetarianOrVegan: false,
    connectedFlights: 2,
  },
  {
    id: 2,
    passengerName: "Amy Winehouse",
    isVegetarianOrVegan: true,
    connectedFlights: 4,
  },
    {
    id: 3,
    passengerName: "Kurt Cobain",
    isVegetarianOrVegan: true,
    connectedFlights: 3,
  },
     {
    id: 3,
    passengerName: "Michael Jackson",
    isVegetarianOrVegan: true,
    connectedFlights: 1,
  },
];

  const orderedList = passengers.sort(
    (passenger1, passenger2) =>  passenger2.connectedFlights - passenger1.connectedFlights
  )

   console.log(orderedList)


EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  gameSelected: PropTypes.array.isRequired,
};

export default function EnhancedTable() {
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('fecha');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isLoading, setIsLoading] = useState(true);
  
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    event.stopPropagation();
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {

    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;
  
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const rows = getRows(setIsLoading);
  

  return (

    <Box sx={{ width: '100%' }}>
      
      <Paper sx={{ width: '100%', mb: 2 }}>
      {
        isLoading ?  
          <Box  className="root-loading">
            <CircularProgress />
          </Box> : 
          <Box ></Box>
      }
        
      <EnhancedTableToolbar numSelected={selected.length} gameSelected={selected} />

        <TableContainer>
            <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
            >
                <EnhancedTableHead
                    gameSelected={selected}
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                />
                <TableBody>
                    {
                        stableSort(rows, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                                const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        key={row.id}
                                        onClick={(event) => handleClick(event, row.id)}
                                        tabIndex={-1}
                                        selected={isItemSelected}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                        >
                                            {row.id}
                                        </TableCell>
                                        <TableCell 
                                            align="left"
                                            sx={{  }}
                                        >
                                            {
                                                row.equipo_B.length ? row.equipo_B.map((item) =>
                                                    (
                                                        <span key={item} className="player-team-span">
                                                            <Chip size="small" className="player-team-chip" label={item} />                                                    
                                                        </span>
                                                    )
                                                    
                                                ) : '-'
                                            }
                                        </TableCell>
                                        <TableCell 
                                            align="left"
                                            sx={{  }}
                                        >
                                            {
                                                row.equipo_N.length ? row.equipo_N.map((item) =>
                                                    (
                                                        <span key={item} className="player-team-span">
                                                            <Chip size="small" className="player-team-chip" label={item} />                                                    
                                                        </span>
                                                    )
                                                    
                                                ) : '-'
                                            }
                                        </TableCell>
                                        <TableCell align="left">{row.fecha}</TableCell>
                                        <TableCell align="left">{row.hora}</TableCell>
                                        <TableCell align="center">{row.goles_B}</TableCell>
                                        <TableCell align="center">{row.goles_N}</TableCell>
                                        <TableCell align="center">{row.resultado}</TableCell>
                                    </TableRow>
                                );
                            }
                        )
                    }
                    {
                    emptyRows > 0 && (
                        <TableRow
                            style={{
                                height: (dense ? 33 : 53) * emptyRows,
                            }}
                        >
                        <TableCell colSpan={6} />
                        </TableRow>
                    )
                    }
                </TableBody>
            </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          labelRowsPerPage={"Filas por página"} 
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

      </Paper>
         
    </Box>
  );
}


import React from "react";
import Box from "@mui/material/Box";
import { Link } from 'react-router-dom';

import xls from "../tesoreria.xls";

export default function Home() {
	return (
		<Box
			align="center"
			sx={{ display: 'flex', flexDirection: 'column' }}
		>
            <Box className='content-pagina-inicio'
                sx={{
                }}>
                <h1> Futbolba, una vida jugando a fútbol</h1>
                <p>
                    Somos un grupo que se ha organizado para jugar fútbol sala o
                    fútbol 7 hace ya más de 25 años. <br />Nos hemos ido renovando con el paso del tiempo en
                    cuanto a participantes, lugar donde jugamos y horarios.
                </p>
                <p>
                    Ahora estamos jugando: <br/> los <b>lunes</b> y <b>jueves</b> a las 20h en
                    <b>CEM Bogatell</b> (C. de Carmen Amaya, 4, 6, 08005 Barcelona). <br/>
                    y los <b>martes</b> a las 21h. en las instalaciones de la Mar Bella (Complex Esportiu Municipal La Mar Bella,
                    Av. del Litoral, 86-96, 08005 Barcelona)
                </p>
            </Box>
            <Box className='content-pagina-inicio'
            sx={{
            }}>
                <h3>Tesorería</h3>
                <p>La tesorería del grupo se está llevando en una hoja de cálculo que, sin pretender ser una contabilidad formal, ayude a controlar el dinero. En el mismo documento también se lleva la relación de los que han pagado la cuota anual.</p>
                
                <p> Periódicamente, se colgará <Link to={xls} target="_blank" rel="noopener">aquí</Link> para que todos podamos conocer el estado de cuentas, etc.</p>
            </Box>
       </Box>
	);
}

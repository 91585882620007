
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import axios from 'axios';

import '../styles.css';

const baseURLPartidos = process.env.REACT_APP_BASE_URL+"/app/api/partidos/";
const baseURLJugadores = process.env.REACT_APP_BASE_URL+"/app/api/jugadores/";


  // JUGADORES hARCODEADOS

  const jugadoresFake = [
      {
        "id": "Alberto Gómez",
        "nombre": "Alberto",
        "apellido": "Gómez",
        "partidosJ": 0,
        "partidosG": 0,
        "partidosE": 0,
        "partidosP": 0,
        "golaverage": 0,
        "clasificacion": 0,
        "clasificacionOld": 0,
        "puntos": 0
      },
      {
        "id": "Alberto Mejías",
        "nombre": "Alberto",
        "apellido": "Mejías",
        "partidosJ": 0,
        "partidosG": 0,
        "partidosE": 0,
        "partidosP": 0,
        "golaverage": 0,
        "clasificacion": 0,
        "clasificacionOld": 0,
        "puntos": 0
      },
      {
        "id": "Ale Ale",
        "nombre": "Ale",
        "apellido": "Ale",
        "partidosJ": 0,
        "partidosG": 0,
        "partidosE": 0,
        "partidosP": 0,
        "golaverage": 0,
        "clasificacion": 0,
        "clasificacionOld": 0,
        "puntos": 0
      },
      {
        "id": "Ali Ali",
        "nombre": "Ali",
        "apellido": "Ali",
        "partidosJ": 0,
        "partidosG": 0,
        "partidosE": 0,
        "partidosP": 0,
        "golaverage": 0,
        "clasificacion": 0,
        "clasificacionOld": 0,
        "puntos": 0
      },
      {
        "id": "Antonio Antonio",
        "nombre": "Antonio",
        "apellido": "Antonio",
        "partidosJ": 0,
        "partidosG": 0,
        "partidosE": 0,
        "partidosP": 0,
        "golaverage": 0,
        "clasificacion": 0,
        "clasificacionOld": 0,
        "puntos": 0
      },
      {
        "id": "Carlos Flores",
        "nombre": "Carlos",
        "apellido": "Flores",
        "partidosJ": 0,
        "partidosG": 0,
        "partidosE": 0,
        "partidosP": 0,
        "golaverage": 0,
        "clasificacion": 0,
        "clasificacionOld": 0,
        "puntos": 0
      },
      {
        "id": "Dani Anarkattack",
        "nombre": "Dani",
        "apellido": "Anarkattack",
        "partidosJ": 0,
        "partidosG": 0,
        "partidosE": 0,
        "partidosP": 0,
        "golaverage": 0,
        "clasificacion": 0,
        "clasificacionOld": 0,
        "puntos": 0
      },
      {
        "id": "Danny Galarza",
        "nombre": "Danny",
        "apellido": "Galarza",
        "partidosJ": 0,
        "partidosG": 0,
        "partidosE": 0,
        "partidosP": 0,
        "golaverage": 0,
        "clasificacion": 0,
        "clasificacionOld": 0,
        "puntos": 0
      },
      {
        "id": "Edu Barba",
        "nombre": "Edu",
        "apellido": "Barba",
        "partidosJ": 0,
        "partidosG": 0,
        "partidosE": 0,
        "partidosP": 0,
        "golaverage": 0,
        "clasificacion": 0,
        "clasificacionOld": 0,
        "puntos": 0
      },
      {
        "id": "Enric Ferrer",
        "nombre": "Enric",
        "apellido": "Ferrer",
        "partidosJ": 0,
        "partidosG": 0,
        "partidosE": 0,
        "partidosP": 0,
        "golaverage": 0,
        "clasificacion": 0,
        "clasificacionOld": 0,
        "puntos": 0
      },
      {
        "id": "Fausto Gongora",
        "nombre": "Fausto",
        "apellido": "Gongora",
        "partidosJ": 0,
        "partidosG": 0,
        "partidosE": 0,
        "partidosP": 0,
        "golaverage": 0,
        "clasificacion": 0,
        "clasificacionOld": 0,
        "puntos": 0
      },
      {
        "id": "Fran Roset",
        "nombre": "Fran",
        "apellido": "Roset",
        "partidosJ": 0,
        "partidosG": 0,
        "partidosE": 0,
        "partidosP": 0,
        "golaverage": 0,
        "clasificacion": 0,
        "clasificacionOld": 0,
        "puntos": 0
      },
      {
        "id": "Frederick Frederick",
        "nombre": "Frederick",
        "apellido": "Frederick",
        "partidosJ": 0,
        "partidosG": 0,
        "partidosE": 0,
        "partidosP": 0,
        "golaverage": 0,
        "clasificacion": 0,
        "clasificacionOld": 0,
        "puntos": 0
      },
      {
        "id": "Giovani Ortiz",
        "nombre": "Giovani",
        "apellido": "Ortiz",
        "partidosJ": 0,
        "partidosG": 0,
        "partidosE": 0,
        "partidosP": 0,
        "golaverage": 0,
        "clasificacion": 0,
        "clasificacionOld": 0,
        "puntos": 0
      },
      {
        "id": "Gorka Cano",
        "nombre": "Gorka",
        "apellido": "Cano",
        "partidosJ": 0,
        "partidosG": 0,
        "partidosE": 0,
        "partidosP": 0,
        "golaverage": 0,
        "clasificacion": 0,
        "clasificacionOld": 0,
        "puntos": 0
      },
      {
        "id": "Guille Moron",
        "nombre": "Guille",
        "apellido": "Moron",
        "partidosJ": 0,
        "partidosG": 0,
        "partidosE": 0,
        "partidosP": 0,
        "golaverage": 0,
        "clasificacion": 0,
        "clasificacionOld": 0,
        "puntos": 0
      },
      {
        "id": "Guillem Banaforte",
        "nombre": "Guillem",
        "apellido": "Banaforte",
        "partidosJ": 0,
        "partidosG": 0,
        "partidosE": 0,
        "partidosP": 0,
        "golaverage": 0,
        "clasificacion": 0,
        "clasificacionOld": 0,
        "puntos": 0
      },
      {
        "id": "Hugo García",
        "nombre": "Hugo",
        "apellido": "García",
        "partidosJ": 0,
        "partidosG": 0,
        "partidosE": 0,
        "partidosP": 0,
        "golaverage": 0,
        "clasificacion": 0,
        "clasificacionOld": 0,
        "puntos": 0
      },
      {
        "id": "Ignacio Llamas",
        "nombre": "Ignacio",
        "apellido": "Llamas",
        "partidosJ": 0,
        "partidosG": 0,
        "partidosE": 0,
        "partidosP": 0,
        "golaverage": 0,
        "clasificacion": 0,
        "clasificacionOld": 0,
        "puntos": 0
      },
      {
        "id": "Ismael Ismael",
        "nombre": "Ismael",
        "apellido": "Ismael",
        "partidosJ": 0,
        "partidosG": 0,
        "partidosE": 0,
        "partidosP": 0,
        "golaverage": 0,
        "clasificacion": 0,
        "clasificacionOld": 0,
        "puntos": 0
      },
      {
        "id": "JR Bel",
        "nombre": "JR",
        "apellido": "Bel",
        "partidosJ": 0,
        "partidosG": 0,
        "partidosE": 0,
        "partidosP": 0,
        "golaverage": 0,
        "clasificacion": 0,
        "clasificacionOld": 0,
        "puntos": 0
      },
      {
        "id": "Javi Aguilera",
        "nombre": "Javi",
        "apellido": "Aguilera",
        "partidosJ": 0,
        "partidosG": 0,
        "partidosE": 0,
        "partidosP": 0,
        "golaverage": 0,
        "clasificacion": 0,
        "clasificacionOld": 0,
        "puntos": 0
      },
      {
        "id": "Javier Gascue",
        "nombre": "Javier",
        "apellido": "Gascue",
        "partidosJ": 0,
        "partidosG": 0,
        "partidosE": 0,
        "partidosP": 0,
        "golaverage": 0,
        "clasificacion": 0,
        "clasificacionOld": 0,
        "puntos": 0
      },
      {
        "id": "Joan Joan",
        "nombre": "Joan",
        "apellido": "Joan",
        "partidosJ": 0,
        "partidosG": 0,
        "partidosE": 0,
        "partidosP": 0,
        "golaverage": 0,
        "clasificacion": 0,
        "clasificacionOld": 0,
        "puntos": 0
      },
      {
        "id": "Jofre Casanovas",
        "nombre": "Jofre",
        "apellido": "Casanovas",
        "partidosJ": 0,
        "partidosG": 0,
        "partidosE": 0,
        "partidosP": 0,
        "golaverage": 0,
        "clasificacion": 0,
        "clasificacionOld": 0,
        "puntos": 0
      },
      {
        "id": "Jony Rubio",
        "nombre": "Jony",
        "apellido": "Rubio",
        "partidosJ": 0,
        "partidosG": 0,
        "partidosE": 0,
        "partidosP": 0,
        "golaverage": 0,
        "clasificacion": 0,
        "clasificacionOld": 0,
        "puntos": 0
      },
      {
        "id": "Jordi8 Juárez",
        "nombre": "Jordi8",
        "apellido": "Juárez",
        "partidosJ": 0,
        "partidosG": 0,
        "partidosE": 0,
        "partidosP": 0,
        "golaverage": 0,
        "clasificacion": 0,
        "clasificacionOld": 0,
        "puntos": 0
      },
      {
        "id": "Jordi Cascan",
        "nombre": "Jordi",
        "apellido": "Cascan",
        "partidosJ": 0,
        "partidosG": 0,
        "partidosE": 0,
        "partidosP": 0,
        "golaverage": 0,
        "clasificacion": 0,
        "clasificacionOld": 0,
        "puntos": 0
      },
      {
        "id": "Jordi JordiFran",
        "nombre": "Jordi",
        "apellido": "JordiFran",
        "partidosJ": 0,
        "partidosG": 0,
        "partidosE": 0,
        "partidosP": 0,
        "golaverage": 0,
        "clasificacion": 0,
        "clasificacionOld": 0,
        "puntos": 0
      },
      {
        "id": "Jordi Valero",
        "nombre": "Jordi",
        "apellido": "Valero",
        "partidosJ": 0,
        "partidosG": 0,
        "partidosE": 0,
        "partidosP": 0,
        "golaverage": 0,
        "clasificacion": 0,
        "clasificacionOld": 0,
        "puntos": 0
      },
      {
        "id": "Jorge Gascue",
        "nombre": "Jorge",
        "apellido": "Gascue",
        "partidosJ": 0,
        "partidosG": 0,
        "partidosE": 0,
        "partidosP": 0,
        "golaverage": 0,
        "clasificacion": 0,
        "clasificacionOld": 0,
        "puntos": 0
      },
      {
        "id": "Jose M. Texidor",
        "nombre": "Jose M.",
        "apellido": "Texidor",
        "partidosJ": 0,
        "partidosG": 0,
        "partidosE": 0,
        "partidosP": 0,
        "golaverage": 0,
        "clasificacion": 0,
        "clasificacionOld": 0,
        "puntos": 0
      },
      {
        "id": "Josep Vals",
        "nombre": "Josep",
        "apellido": "Vals",
        "partidosJ": 0,
        "partidosG": 0,
        "partidosE": 0,
        "partidosP": 0,
        "golaverage": 0,
        "clasificacion": 0,
        "clasificacionOld": 0,
        "puntos": 0
      },
      {
        "id": "José Fiestas",
        "nombre": "José",
        "apellido": "Fiestas",
        "partidosJ": 0,
        "partidosG": 0,
        "partidosE": 0,
        "partidosP": 0,
        "golaverage": 0,
        "clasificacion": 0,
        "clasificacionOld": 0,
        "puntos": 0
      },
      {
        "id": "Luis portero",
        "nombre": "Luis",
        "apellido": "portero",
        "partidosJ": 0,
        "partidosG": 0,
        "partidosE": 0,
        "partidosP": 0,
        "golaverage": 0,
        "clasificacion": 0,
        "clasificacionOld": 0,
        "puntos": 0
      },
      {
        "id": "Marcos Mejías",
        "nombre": "Marcos",
        "apellido": "Mejías",
        "partidosJ": 0,
        "partidosG": 0,
        "partidosE": 0,
        "partidosP": 0,
        "golaverage": 0,
        "clasificacion": 0,
        "clasificacionOld": 0,
        "puntos": 0
      },
      {
        "id": "Michael Michael",
        "nombre": "Michael",
        "apellido": "Michael",
        "partidosJ": 0,
        "partidosG": 0,
        "partidosE": 0,
        "partidosP": 0,
        "golaverage": 0,
        "clasificacion": 0,
        "clasificacionOld": 0,
        "puntos": 0
      },
      {
        "id": "Miquel de la Pinta",
        "nombre": "Miquel",
        "apellido": "de la Pinta",
        "partidosJ": 0,
        "partidosG": 0,
        "partidosE": 0,
        "partidosP": 0,
        "golaverage": 0,
        "clasificacion": 0,
        "clasificacionOld": 0,
        "puntos": 0
      },
      {
        "id": "Pablo Grana",
        "nombre": "Pablo",
        "apellido": "Grana",
        "partidosJ": 0,
        "partidosG": 0,
        "partidosE": 0,
        "partidosP": 0,
        "golaverage": 0,
        "clasificacion": 0,
        "clasificacionOld": 0,
        "puntos": 0
      },
      {
        "id": "Samu Gregorio",
        "nombre": "Samu",
        "apellido": "Gregorio",
        "partidosJ": 0,
        "partidosG": 0,
        "partidosE": 0,
        "partidosP": 0,
        "golaverage": 0,
        "clasificacion": 0,
        "clasificacionOld": 0,
        "puntos": 0
      },
      {
        "id": "Soufian Soufian",
        "nombre": "Soufian",
        "apellido": "Soufian",
        "partidosJ": 0,
        "partidosG": 0,
        "partidosE": 0,
        "partidosP": 0,
        "golaverage": 0,
        "clasificacion": 0,
        "clasificacionOld": 0,
        "puntos": 0
      },
      {
        "id": "Steve Laurie",
        "nombre": "Steve",
        "apellido": "Laurie",
        "partidosJ": 0,
        "partidosG": 0,
        "partidosE": 0,
        "partidosP": 0,
        "golaverage": 0,
        "clasificacion": 0,
        "clasificacionOld": 0,
        "puntos": 0
      },
      {
        "id": "Victor Lirola",
        "nombre": "Victor",
        "apellido": "Lirola",
        "partidosJ": 0,
        "partidosG": 0,
        "partidosE": 0,
        "partidosP": 0,
        "golaverage": 0,
        "clasificacion": 0,
        "clasificacionOld": 0,
        "puntos": 0
      },
      {
        "id": "Vincent Ronald",
        "nombre": "Vincent",
        "apellido": "Ronald",
        "partidosJ": 0,
        "partidosG": 0,
        "partidosE": 0,
        "partidosP": 0,
        "golaverage": 0,
        "clasificacion": 0,
        "clasificacionOld": 0,
        "puntos": 0
      }
  ]

function formPlayer() { 
/*     const [ jugadoresB, setJugadoresB]  = React.useState([]);
    const [ jugadoresN, setJugadoresN]  = React.useState([]);
    const [ jugadores , setJugadores] = React.useState([]); */

    const [ result] = React.useState([])
    
    const handleSubmit = (event) => {
        event.preventDefault();

        axios.get(baseURLPartidos)
        .then(res => {
            const partidos = res.data;
            console.log('partidos')
            console.log(partidos)
            console.log('jugadores harcodeados')
            console.log(jugadoresFake)
          
            jugadoresFake.forEach( (jugador) => {
                           
                const { id } = jugador
                
                partidos.forEach((partido) => {

                    const { equipo_B, equipo_N, goles_B, goles_N } = partido
                    
/*                  const oldCriteria =  jugador.partidosJ ? jugador.partidosJ + (jugador.golaverage * 0.0001) : 1
                    const newCriteria = jugador.partidosJ ? jugador.partidosJ - jugador.partidosJ * (jugador.partidosJ - 1) / 200 : 1

                    const oldCounter = (jugador.puntos/oldCriteria).toFixed(2)
                    const mewCounter = (jugador.puntos/newCriteria).toFixed(2) */

                    /* console.log(id) */

                    if(equipo_B.includes(id) &&  goles_B > goles_N) {
                        jugador.puntos += 3
                        jugador.partidosJ += 1
                        jugador.partidosG += 1
                        jugador.golaverage += (goles_B - goles_N)
                        jugador.clasificacion = (jugador.puntos/(jugador.partidosJ - jugador.partidosJ * (jugador.partidosJ - 1) / 200 ) + (jugador.golaverage * 0.0001)).toFixed(4)
                        jugador.clasificacionOld = (jugador.puntos/jugador.partidosJ + (jugador.golaverage * 0.0001)).toFixed(4)
                    /*     console.log ('jugador blanco gana') */
                    }
                    if(equipo_B.includes(id) &&  goles_N > goles_B) {
                        jugador.puntos += 0
                        jugador.partidosJ += 1
                        jugador.partidosP += 1
                        jugador.golaverage += (goles_B - goles_N)
                        jugador.clasificacion = (jugador.puntos/(jugador.partidosJ - jugador.partidosJ * (jugador.partidosJ - 1) / 200 ) + (jugador.golaverage * 0.0001)).toFixed(4)
                        jugador.clasificacionOld = (jugador.puntos/jugador.partidosJ + (jugador.golaverage * 0.0001)).toFixed(4)
                      /*   console.log ('jugador blanco pierde') */
                    }
                    if(equipo_B.includes(id) &&  goles_N === goles_B) {
                        jugador.puntos += 1
                        jugador.partidosJ += 1
                        jugador.partidosE += 1
                        jugador.golaverage += (goles_B - goles_N)
                        jugador.clasificacion = (jugador.puntos/(jugador.partidosJ - jugador.partidosJ * (jugador.partidosJ - 1) / 200) + (jugador.golaverage * 0.0001)).toFixed(4)
                        jugador.clasificacionOld = (jugador.puntos/jugador.partidosJ + (jugador.golaverage * 0.0001)).toFixed(4)
                       /*  console.log ('jugador blanco empata') */
                    }
                    if(equipo_N.includes(id) &&  goles_N > goles_B) {
                        jugador.puntos += 3
                        jugador.partidosJ += 1
                        jugador.partidosG += 1
                        jugador.golaverage += (goles_N - goles_B)
                        jugador.clasificacion = (jugador.puntos/(jugador.partidosJ - jugador.partidosJ * (jugador.partidosJ - 1) / 200) + (jugador.golaverage * 0.0001)).toFixed(4)
                        jugador.clasificacionOld = (jugador.puntos/jugador.partidosJ + (jugador.golaverage * 0.0001)).toFixed(4)
    /*                     console.log ('jugador negro gana') */
                    }
                    if(equipo_N.includes(id) &&  goles_B > goles_N) {
                        jugador.puntos += 0
                        jugador.partidosJ += 1
                        jugador.partidosP += 1
                        jugador.golaverage += (goles_N - goles_B)
                        jugador.clasificacion = (jugador.puntos/(jugador.partidosJ - jugador.partidosJ * (jugador.partidosJ - 1) / 200) + (jugador.golaverage * 0.0001)).toFixed(4)
                        jugador.clasificacionOld = (jugador.puntos/jugador.partidosJ + (jugador.golaverage * 0.0001)).toFixed(4)
                  /*       console.log ('jugador negro pierde') */
                    }
                    if(equipo_N.includes(id) &&  goles_N === goles_B) {
                        jugador.puntos += 1
                        jugador.partidosJ += 1
                        jugador.partidosE += 1
                        jugador.golaverage += (goles_N - goles_B)
                        jugador.clasificacion = jugador.puntos/(jugador.partidosJ - jugador.partidosJ * (jugador.partidosJ - 1) / 200 )+ (jugador.golaverage * 0.0001).toFixed(4)
                        jugador.clasificacionOld = (jugador.puntos/jugador.partidosJ + (jugador.golaverage * 0.0001)).toFixed(4)
       /*                  console.log ('jugador negro empata') */
                    }
                })
                
                console.log(result)
                return jugadoresFake
            })
            console.log('Check table')
            console.table(jugadoresFake)

            axios.get(baseURLJugadores)
            .then( response => {
              const jugadoresOrigin = response.data;
              console.log('Origin table')
              console.table(jugadoresOrigin)

              const processCheck = (origin, check, index, term) => {
                if (origin !== check) {
                  console.log(`el descuadre esta en: ${term} de `)
                  console.log(jugadoresOrigin[index].nombre)
                  console.log(`${origin} en origen:` )
                  console.log(`${check} check function:` )

                  
                /*   let abc1 = (`el descuadre esta en: ${term} de `)
                  let abc2 = (jugadoresOrigin[index].nombre)
                  let abc3 = (`${origin} en origen:` )
                  let abc4 = (`${check} check function:` ) */


                }
                return result
              }

  
                if (jugadoresOrigin.length == jugadoresFake.length) {
                  jugadoresOrigin.map((jugador, index) => {

                    processCheck(jugadoresOrigin[index].puntos, jugadoresFake[index].puntos, index, 'puntos')
                    processCheck(jugadoresOrigin[index].golaverage, jugadoresFake[index].golaverage, index , 'golaverage')
                    processCheck(jugadoresOrigin[index].partidosJ, jugadoresFake[index].partidosJ, index , 'partidos Jugados')
                    processCheck(jugadoresOrigin[index].partidosG, jugadoresFake[index].partidosG, index , 'partidos Ganados')
                    processCheck(jugadoresOrigin[index].partidosP, jugadoresFake[index].partidosP, index , 'partidos Perdidos')
                    processCheck(jugadoresOrigin[index].partidosE, jugadoresFake[index].partidosE, index , 'partidos Empatados')
                    processCheck(parseFloat(jugadoresOrigin[index].clasificacion), parseFloat(jugadoresFake[index].clasificacion), index, 'clasificacion')
                    processCheck(parseFloat(jugadoresOrigin[index].clasificacionOld), parseFloat(jugadoresFake[index].clasificacionOld), index, 'clasifiacionOld')

                  })
                }
            })
            .catch(error => {
                console.log(error)
            })
          })
        .catch(error => {
            console.log(error)
        })
    };
  
    return (
        <div>
            
            <hr/>
            <br/>
            <br/>
            <br/>
            <h1>Check Clasificación</h1>
           <form onSubmit={handleSubmit}>
                <Box sx={ {
                    }}>
                {/*     <Box 
                       sx={{
                         '& .MuiTextField-root': { m: 1, width: '25ch' },
                       }}>
                        <FormControl
                            >
                            <TextField
                                required
                                id="nombre"
                                name="nombre"
                                label="nombre"
                                value={formValues.nombre}
                                onChange={handleInputChange}
                            />
                        </FormControl>

                        <FormControl
                                >
                            <TextField
                                required
                                id="apellido"
                                name="apellido"
                                label="apellido"
                                value={formValues.apellido}
                                onChange={handleInputChange}
                            />
                        </FormControl>
               
                        <FormControl
                                >
                            <TextField
                                className="hidden-input hidden"
                                type="hidden"
                                id="id"
                                name="id"
                                label="id"
                                value={formValues.id = formValues.nombre + '_' + formValues.apellido}
                                onChange={handleInputChange}
                            />
                        </FormControl>

                        <FormControl 
                                >
                            <TextField
                                    required
                                    id="telefono"
                                    name="telefono"
                                    label="telefono"
                                    value={formValues.telefono}
                                    onChange={handleInputChange}
                                    inputProps={{ inputMode: 'numeric', maxLength: 9 , pattern: '[0-9]*' }}
                                />
                        </FormControl>

                        <FormControl>
                            <TextField
                                    required
                                    className='form-input'
                                    id="email"
                                    name="email"
                                    label="email"
                                    value={formValues.email}
                                    onChange={handleInputChange}
                                />
                        </FormControl>

                    </Box> */}
                <div><br /><br /></div>
                    <Box>
                        <Button
                            type="submit"
                            className='button-primary'
                            variant="contained"
                            color="primary"
                        >
                            Check Clasificación
                        </Button>
                    </Box>
                </Box>
            </form>

            { result }
        </div>
        )
    
}


export default formPlayer
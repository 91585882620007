import React from 'react';
import TablaPartidosAdmin from '../components/tablaPartidosAdmin'; 
import FormPlayer from '../components/formAddPlayer';
import CheckClasificacion from '../components/formCheckClasificacion';
import { Link } from 'react-router-dom';

import Box from "@mui/material/Box";

export default function Partidos() {

    return (
        <Box
			align="center"
			sx={{ display: 'flex', flexDirection: 'column' }}
		>   
            <br />
            <div className="menu-add-results">
                <Link to="/registro-partidos">
                    Registrar nuevo partido
                </Link>
            </div>
            <h1> Registro de partidos</h1>
            <br />
            <TablaPartidosAdmin />
            <br/>
            <br/>
            <br/>
            <br/>
            < FormPlayer /> 

            <br/>
            <br/>
            <br/>
            <br/>
            <hr/>
            <hr/>
            <br/>
            <br/>
            <br/>
            <br/>
            < CheckClasificacion /> 

        </Box>
    )
}
import React from 'react';
import FormGame from '../components/formAddGame'; 


export default function RegistroPartidos() {

    return (
        <div>          
            <FormGame />
        </div>
    )
}
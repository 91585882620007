import React, { useState } from "react";
/* import { useDispatch } from "react-redux"; */
/* import { useHistory } from "react-router-dom"; */
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

function Signin() {


  const [userData, setUserData] = useState({ username: "", password: "" });
  const [errorMessage, setErrorMessage] = useState({ value: "" });
/*   const history = useHistory(); */
  console.log("auth", localStorage.getItem("isAuthenticated"));

  const handleInputChange = (e) => {
    setUserData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //if username or password field is empty, return error message
    if (userData.username === "" || userData.password === "") {
      setErrorMessage((prevState) => ({
        value: "Campo de usuario o password vacío",
      }));
    } else if (userData.username === "presi" && userData.password === "20222022") {
      //Signin Success
      localStorage.setItem("isAuthenticated", "true");
      window.location.pathname = "/partidos-admin";
    } else {
      //If credentials entered is invalid
      setErrorMessage((prevState) => ({ value: "Usuario o password incorrecto" }));
    }
  };

  return (
    <div className="text-center">
      <h1>Autentificación</h1>
      <Box
        component="form"
        sx={{
          '& > :not(style)': { m: 1, width: '800' },
        }}
        noValidate
        autoComplete="off"
      >
        <Box
          sx={{
            '& > :not(style)': { m: 1 },
            display: "flex",
          }}
          noValidate
          autoComplete="off"
        >
            <TextField 
              id="outlined-basic" 
              label="Username" 
              variant="outlined" 
              name="username"
              required
              onChange={(e) => handleInputChange(e)}
            />

            <TextField
              id="filled-basic"
              label="Password" 
              variant="outlined" 
              name="password"
              type="password"
              required
              onChange={(e) => handleInputChange(e)}
            />


        </Box>
        <Box
          sx={{
            '& > :not(style)': { m: 1, width: '25ch' },
          }}
          noValidate
          autoComplete="off"
        >
            <Button 
              type="submit"
              className="btn btn-primary"
              onClick={handleSubmit}
              variant="contained">
              login
            </Button>
        </Box>
    </Box>


        {errorMessage.value && (
          <p className="text-danger"> {errorMessage.value} </p>
        )}

    </div>
  );
}

export default Signin;
import * as React from 'react';
import {useState, useEffect} from 'react';
import axios from 'axios';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';


const getPlayers = () => {
       
  const [players, setPlayers] = React.useState([])

  useEffect(() => {
      axios.get(process.env.REACT_APP_BASE_URL+"/app/api/jugadores/")
      .then(res => {
          console.log(res)
          setPlayers(res.data)
      })
      .catch(error => {
          console.log(error)
      })
  }, [])

  return  ( players )
}


export default function listJugadores() {

  const jugadores = getPlayers();
  const [isLoading, setIsLoading] = useState(false);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  
  const [dense] = React.useState(true);

  return (
    
    <Box sx={{ width: '100%' }}>
      
      <br />
      <br />
      <Paper sx={{ width: '100%', mb: 2 }}>
          {
            isLoading ?  
              <Box  className="root-loading">
                <CircularProgress />
              </Box> : 
              <Box ></Box>
          }
          <Grid 
                maxWidth={900} 
                container 
                spacing={{ xs: 1, md: 1 }} 
                columns={{ xs: 1, sm: 8, md: 8 }}
                
                >
              {jugadores.map((jugadores, index) => (
                <Grid item xs={1} sm={2} md={2} key={index}
                padding={0}
                >

                  <ListItem 
                    dense> 
                    <ListItemIcon className="listIconStyle" >
                    <AccountCircle /> 
                    </ListItemIcon>
                    <ListItemText className="item-list--jugadores" sx={{ width:'50%'}} primary={jugadores.nombre + ' ' + jugadores.apellido} />
                    <ListItemText primary={ index + 1} />
                 
                </ListItem>
                </Grid>
              ))}

            
          </Grid>

      </Paper>

    </Box>
  );
}


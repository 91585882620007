import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import SportsSoccer from '@mui/icons-material/SportsSoccer';
import Toolbar from '@mui/material/Toolbar';

import NavBar from '../Navbar.js'

const drawerWidth = 240;

function ResponsiveDrawer(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
        <Toolbar />
        <Divider />
            <List>
                <ListItem disablePadding>
                    <ListItemButton to="/">
                            <ListItemIcon>
                                <SportsSoccer />
                            </ListItemIcon>
                        <ListItemText primary={'Inicio'} />
                    </ListItemButton>

                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton to="/jugadores">
                            <ListItemIcon>
                                <SportsSoccer />
                            </ListItemIcon>
                        <ListItemText primary={'jugadores'} />
                
                    </ListItemButton>
                </ListItem>

                <ListItem disablePadding>
                    
                    <ListItemButton to="/clasificacion"> 
                        <ListItemIcon>
                            <SportsSoccer />
                        </ListItemIcon>
                        <ListItemText primary={'Clasificación'} />

                    </ListItemButton>
                </ListItem>

                <ListItem disablePadding>
                    <ListItemButton to="/partidos">
                            <ListItemIcon>
                                <SportsSoccer />
                            </ListItemIcon>
                            <ListItemText primary={'Partidos'} />
                    </ListItemButton>
                </ListItem>
            </List>

        <Divider />

{/*             <List>
                {['All mail', 'Trash', 'Spam'].map((text, index) => (
                <ListItem key={text} disablePadding>
                    <ListItemButton>
                    <ListItemIcon>
                        {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                    </ListItemIcon>
                    <ListItemText primary={text} />
                    </ListItemButton>
                </ListItem>
                ))}
            </List> */}
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
{/*         <CssBaseline /> */}
        <AppBar
            position="fixed"
            sx={{
            /* width: { sm: `calc(100% - ${drawerWidth}px)` }, */
            backgroundColor: "transparent",
            boxShadow: 0,
            padding: 0,
            /* ml: { sm: `${drawerWidth}px` }, */
            }}
        >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 0, display: { sm: 'none' } }}
                    >
                        <MenuIcon sx={{ color: "black"}} />
                    </IconButton>

                    <NavBar    />

                </Toolbar>
        </AppBar>

        <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
        >
   
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
            >
              {drawer}
            </Drawer>
        </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;

import * as React from 'react';
import {useState, useEffect} from 'react';
import axios from 'axios';

import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
/* import Button from '@mui/material/Button'; */
import Chip from '@mui/material/Chip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import { visuallyHidden } from '@mui/utils';

/* import TransitionsModal from './modal'; */

function getRows(setIsLoading) {
    const [rows, setRows] = useState([])
  
    useEffect(() => {
        axios.get(process.env.REACT_APP_BASE_URL+'/app/api/partidos/')
        .then(res => {
            setRows(res.data)
            setIsLoading(false);
        })
        .catch(error => {
            console.log(error)
        })

    }, [])
  return  ( rows )
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


function EnhancedTableHead(props) {

  const { order, orderBy, onRequestSort } =
    props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {columnas.map((columna) => (
          <TableCell
            key={columna.id}
            align={columna.numeric ? 'center' : 'left'}
            padding={columna.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === columna.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === columna.id}
              direction={orderBy === columna.id ? order : 'desc'}
              onClick={createSortHandler(columna.id)}
            >
              {columna.label}
              {orderBy === columna.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'orden descendente' : 'orden ascendente'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}


EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


const columnas = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'Partidos',
  },
  {
    id: 'equipo_B',
    numeric: false,
    disablePadding: false,
    label: 'Equipo Blanco',
  },
  {
    id: 'equipo_N',
    numeric: false,
    disablePadding: false,
    label: 'Equipo Negro',
  },
  {
    id: 'fecha',
    numeric: false,
    disablePadding: false,
    label: 'Fecha',
  },
  {
    id: 'hora',
    numeric: false,
    disablePadding: false,
    label: 'Hora',
  },
  {
    id: 'goles_B',
    numeric: true,
    disablePadding: false,
    label: 'Goles E. B.',
  },
  {
    id: 'goles_N',
    numeric: true,
    disablePadding: false,
    label: 'Goles E. N.',
  },
  {
    id: 'resultado',
    numeric: false,
    disablePadding: false,
    label: 'Resultados',
  }
];


export default function EnhancedTable() {
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('fecha');
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isLoading, setIsLoading] = useState(true);
  
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

/*   const handleClick = (event, id) => {

    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };
 */
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const rows = getRows(setIsLoading);
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

 
  

  return (

    <Box sx={{ width: '100%' }}>
      
      <Paper sx={{ width: '100%', mb: 2 }}>
      {
        isLoading ?  
          <Box  className="root-loading">
            <CircularProgress />
          </Box> : 
          <Box ></Box>
      }

        <TableContainer>
            <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
            >
                <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                />
                <TableBody>
                    {
                        stableSort(rows, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        key={row.id}
                       
                                    >
                                        <TableCell
                                            align="left"
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                        >
                                            {row.id}
                                        </TableCell>
                                        <TableCell 
                                            align="left"
                                            sx={{  }}
                                        >
                                            {
                                                row.equipo_B.length ? row.equipo_B.map((item) =>
                                                    (
                                                        <span key={item} className="player-team-span">
                                                            <Chip size="small" className="player-team-chip" label={item.split(' ')[0] + ' ' + item[0]} />                                                    
                                                        </span>
                                                    )
                                                    
                                                ) : '-'
                                            }
                                        </TableCell>
                                        <TableCell 
                                            align="left"
                                            sx={{  }}
                                        >
                                            {
                                                row.equipo_N.length ? row.equipo_N.map((item) =>
                                                    (
                                                        <span key={item} className="player-team-span">
                                                            <Chip size="small" className="player-team-chip" label={
                                                              item.split(' ')[0] + ' ' + item.split(' ')[1][0]
                                                            } />
                                                        </span>
                                                    )
                                                    
                                                ) : '-'
                                            }
                                        </TableCell>
                                        <TableCell align="left">{row.fecha}</TableCell>
                                        <TableCell align="left">{row.hora}</TableCell>
                                        <TableCell align="center">{row.goles_B}</TableCell>
                                        <TableCell align="center">{row.goles_N}</TableCell>
                                        <TableCell align="center">{row.resultado}</TableCell>
                                    </TableRow>
                                );
                            }
                        )
                    }
                    {
                    emptyRows > 0 && (
                        <TableRow
                            style={{
                                height: (dense ? 33 : 53) * emptyRows,
                            }}
                        >
                        <TableCell colSpan={6} />
                        </TableRow>
                    )
                    }
                </TableBody>
            </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          labelRowsPerPage={"Filas por página"} 
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

      </Paper>
         
    </Box>
  );
}


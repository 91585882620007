
import React, {Fragment} from 'react';
import Home from './pages/Home';
import Jugadores from './pages/Jugadores';
import Clasificacion from './pages/Clasificacion';
import Partidos from './pages/Partidos';
import PartidosAdmin from './pages/PartidosAdmin';
import Signin from './pages/Signin';
import RegistroPartidos from './pages/RegistroPartidos';
import { BrowserRouter as Router,  Route, Routes } from 'react-router-dom';
import ResponsiveDrawer from './components/responsiveDrawer';


function App() {
  const isAuthenticated = localStorage.getItem("isAuthenticated");

  return (
        <div className="App"
        >
          <Router>
            <header className="App-header">
              <ResponsiveDrawer />
            </header>
            <div>
              <br />
              <br />
              <br />
              <br />
            </div>
            <div className="container" > 
    
            <Fragment>
              <Routes>
                <Route exact path="/" element={<Home />} /> 
                <Route exact path="/jugadores" element={<Jugadores/>} />
                <Route path="/partidos" element={<Partidos />} />
                <Route path="/clasificacion" element={<Clasificacion />} />
                <Route path="/registro-partidos" element={isAuthenticated ?  <RegistroPartidos/> : <Signin/>} />
                <Route path="/signin" element={<Signin />} />
                <Route path="/partidos-admin" element={isAuthenticated ?  <PartidosAdmin/> : <Signin/>} />
              </Routes>
            </Fragment>
            </div>
          </Router>
        </div>
  );
}


export default App;
import * as React from 'react';
import logo from './logo.svg';
import './index.css';
import './styles.css';
import Box from '@mui/material/Box';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';


export default function Navbar () {
    return (
        <nav className="nav" sx={{ }}>
            <div className="nav-logo">
                <Link to="/"  className="">
                    <h1>futbolba</h1>
                    <img src={logo} className="App-logo" alt="futbolba" />
                </Link>
            </div> 
           <Box component="div" sx={{ display: { xs: 'none', sm: 'flex' }  }}>
                <ul >
                    <CustomLink to="/jugadores"> Jugadores</CustomLink>
                    <CustomLink to="/clasificacion"> Clasificación</CustomLink>
                    <CustomLink to="/partidos"> Partidos</CustomLink> 
                </ul>
            </Box>
        </nav>
    )
}
 function CustomLink({ to, children, ...props}) {
    const resolvedPath = useResolvedPath(to)
    const isActive = useMatch( { path: resolvedPath.pathname, end: true } )
    return (
        <li className={isActive ? "active" : ""}>
            <Link to={to} {...props}>
                {children} 
            </Link>
        </li>
    )
 }

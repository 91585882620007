
import React from 'react';
import TablaClasificacion from '../components/tablaClasificacion';

export default function Clasificación() {
    return (
        <div>
            <h1> Clasificación</h1>
            <div className="menu-add-results">
            
            </div>
            <TablaClasificacion />
        </div>
    )
}